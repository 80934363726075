<template>
  <div class="col-12 col-lg-6 c g">
    <div class="card card-body">
        <p>
            حدد العمليات وقم بعرض احصائية بمن لديه شهادات ومن لم يتم انشاء شهادات له.
        </p>
        <template v-for="result in results">
            <div style="padding: 5px" :key="result._id">
                <b-form-checkbox
                    v-model="selected"
                    :value="result._id"
                >
                    {{ result.title }}
                </b-form-checkbox>
            </div>
        </template>
        <div class="col-12 text-center">
            <button class="btn btn-primary" @click="statsx()" v-b-modal.stats>
                <i class="fa fa-pie-chart"></i>
                عرض الاحصائية
            </button>
        </div>
        <b-modal id="stats" title="الاحصائية" hide-footer size="lg">
            <div class="col-12 text-center" v-if="loading">
                <img :src="require('@/assets/images/loading.svg')" style="width: 100px">
            </div>
            <div class="col-12 table-responsive" v-if="!loading && stats.classes">
                <table class="table table-bordered table-sm table-striped table-hover">
                    <thead>
                        <th>
                            الصف
                        </th>
                        <th>
                            الفصل
                        </th>
                        <th>
                            الكل
                        </th>
                        <th>
                            الموجود لهم
                            الشهادات
                        </th>
                        <th>
                            عرض الطلاب
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="c in stats.classes" :key="c.classname + c.classroom">
                            <td>
                                {{ c.classname }}
                            </td>
                            <td>
                                {{ c.classroom }}
                            </td>
                            <td>
                                {{ c.all }}
                            </td>
                            <td>
                                {{ c.found }}
                            </td>
                            <td>
                                <button class="btn btn-primary btn-sm" @click="custom=c.classname+'&'+c.classroom"><i class="fa fa-users"></i> عرض الطلاب</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br>
                <table class="table table-bordered table-sm table-striped table-hover" v-if="custom">
                    <thead>
                        <th>
                            الطالب
                        </th>
                        <th>
                            لديه شهادة
                        </th>
                    </thead>
                    <tbody>
                        <template v-for="s in stats.students">
                            <tr :key="s.classname + s.classroom+s.name" v-if="custom == s.classname+'&'+s.classroom">
                                <td>
                                    {{ s.name }}
                                </td>
                                <td>
                                    {{ s.found ? 'نعم' : 'لا' }}
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </b-modal>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox, VBModal, BModal, } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BFormCheckbox, BModal,
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            results: [],
            stats: [],
            selected: [],
            loading: false,
            custom: ""
        }
    },
    created(){
        if(!checkPer("results")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        $.post(api + '/user/results/history', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loaded = true;
            if(r.status != 100){
                alert(r.response, 200)
            }else{
                g.results = r.response
            }
        }).fail(function(){
            alert("حدث خطأ", 200)
        })
    },
    methods: {
        statsx(){
            var g = this;
            g.loading = true;
            g.custom = false
            $.post(api + '/user/results/stats', {
                jwt: this.user.jwt,
                selected: g.selected
            }).then(function(r){
                g.loading = false;
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    g.stats = r.response
                }
            }).fail(function(){
                g.loading = false;
                alert("حدث خطأ", 200)
            })
        }
    }
}
</script>

<style>

</style>